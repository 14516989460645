import { IUserGroupPermission, UserGroupPermissionsClient } from 'api/api';
import { AsyncOperationBuilder, retryAsyncOperation } from 'utilities/api-helper';
import { userGroupPermissionsClient } from '../api-clients';
import { GetUserGroupPermissions } from './actions';

export async function getUserPermission() {
	return AsyncOperationBuilder(
		GetUserGroupPermissions,
		apiClient =>
			retryAsyncOperation<IUserGroupPermission[]>(() => (apiClient as UserGroupPermissionsClient).getByUserId(), 5000, 3),
		{},
		userGroupPermissionsClient
	);
}

