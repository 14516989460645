import {
	BaleStorageClient,
	BaleStorageDto,
	IBaleStorageDto,
	IUnitDetailsDto,
	SelectedLanguage,
	UnitDetailsClient,
	UnitDetailsDto
} from 'api/api';
import { Action, Dispatch } from 'redux';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { baleStorageClient, unitDetailsClient } from '../api-clients';
import {
	GetDropdownOptions,
	GetMachineDetailsAction, putMachineDetailsAction, UpdateIsFavouriteAction, UpsertBaleStorageAction
} from './actions';

export async function getMachineDetails(serialNumber: string) {
	return AsyncOperationBuilder(
		GetMachineDetailsAction,
		apiClient => (apiClient as UnitDetailsClient).get(serialNumber),
		{ serialNumber },
		unitDetailsClient
	);
}

export async function getUnitDetails(serialNumber: string, language: SelectedLanguage) {
	return AsyncOperationBuilder(
		GetMachineDetailsAction,
		apiClient => (apiClient as UnitDetailsClient).getByLanguage(serialNumber, language),
		{ serialNumber },
		unitDetailsClient
	);
}

export async function getDropDownOptions() {
	return AsyncOperationBuilder(
		GetDropdownOptions,
		apiClient => (apiClient as UnitDetailsClient).getDropDownData(),
		{},
		unitDetailsClient
	);
}

export async function UpsertMachineDetails(machineDetailsDto: IUnitDetailsDto, updateCoordinates: boolean = false) {
	return AsyncOperationBuilder(
		putMachineDetailsAction,
		apiClient => (apiClient as UnitDetailsClient).put(machineDetailsDto as UnitDetailsDto, updateCoordinates),
		machineDetailsDto,
		unitDetailsClient
	);
}

export async function UpsertMachineDetailsBasicUser(machineDetailsDto: IUnitDetailsDto) {
	return AsyncOperationBuilder(
		putMachineDetailsAction,
		apiClient => (apiClient as UnitDetailsClient).putBasicUser(machineDetailsDto as UnitDetailsDto),
		machineDetailsDto,
		unitDetailsClient
	);
}

//Baler functions
export function UpsertBaleStorage(baleStorageInput: IBaleStorageDto, addNew: boolean) {
	const baleStorage = new BaleStorageDto();
	baleStorage.init(baleStorageInput);

	if (addNew)
		return AsyncOperationBuilder(
			UpsertBaleStorageAction,
			apiClient => (apiClient as BaleStorageClient).createBaleStorage(baleStorage),
			baleStorageInput,
			baleStorageClient
		);
	else
		return AsyncOperationBuilder(
			UpsertBaleStorageAction,
			apiClient => (apiClient as BaleStorageClient).updateBaleStorage(baleStorage),
			baleStorageInput,
			baleStorageClient
		);
}
export const UpdateIsFavourite = (unitDetails: IUnitDetailsDto, isFavourite: boolean) => {
	return (dispatch: Dispatch<Action<{}>>) => {
		if (!unitDetails.unitId) return;
		dispatch(UpdateIsFavouriteAction({ unitId: unitDetails.unitId, isFavourite }));
	};
}