import { ApiException } from 'api/api';
import { Action, Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { ApiClient } from 'state/ducks/api-clients';
import { localized, localizedDynamic } from 'state/i18n';
import { AsyncActionCreators } from 'typescript-fsa';
import { signOut } from './auth-helper';

type ErrorResponse = {
	message: string;
};

export const AsyncOperationBuilder = <TApiInput, TApiReturn>(
	action: AsyncActionCreators<TApiInput, TApiReturn, any>,
	apiCall: (cl: ApiClient) => Promise<TApiReturn>,
	apiInput: TApiInput,
	client: ApiClient,
	rethrowException: boolean = false
) => async (dispatch: Dispatch<Action<any>>) => {
	dispatch(action.started(apiInput));

	try {
		let apiReturnValue: TApiReturn = await apiCall(client);
		dispatch(action.done({ params: apiInput!, result: apiReturnValue } as any));
	} catch (error: any) {
		if (error.status) {
			if (error.status === 401) {
				signOut();
			}
			let apiException = showApiError(error);

			dispatch(
				action.failed({
					params: apiInput!,
					error: { code: apiException.status, message: apiException.message },
				} as any)
			);

			if (rethrowException) throw error;
		} else {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);

			dispatch(
				action.failed({
					params: apiInput!,
					error: { code: 0, message: error.toString() },
				} as any)
			);
		}
	}
};

export const AsyncOperationBuilderWithoutState = async <TApiReturn>(
	apiCall: (cl: ApiClient) => TApiReturn,
	client: ApiClient,
	rethrowException: boolean = false
) => {
	try {
		let apiReturnValue: TApiReturn = await apiCall(client);
		return apiReturnValue;
	} catch (error: any) {
		if (error.status) {
			let apiException = showApiError(error);

			if (apiException.status === 401) {
				signOut();
			}
			if (rethrowException) throw error;
		} else {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);
		}
	}
};

const showApiError = (error: any): ApiException => {
	let apiException = error as ApiException;

	const responseFromJson = JSON.parse(apiException.response) as ErrorResponse;
	let message: string = '';

	if (responseFromJson?.message) {
		const localizedResponseIfAny = localizedDynamic(responseFromJson.message);
		message = localizedResponseIfAny;

		const prefix = responseFromJson.message.substring(0, responseFromJson.message.indexOf('_')) ?? '';

		if (prefix.toLowerCase() === 'warning') {
			notificationService.showWarningMessage(message, undefined);
		} else {
			const msg = getErrorHeaderAndBody(apiException, message);
			notificationService.showErrorMessageWithError(msg.header, msg.messageBody, error);
		}
	} else {
		notificationService.showErrorMessageWithError(apiException.message, undefined, error);
	}

	return apiException;
};

const getErrorHeaderAndBody = (exception: ApiException, message: string): { header: string, messageBody: string | undefined } => {
	switch (true) {
		case exception.status === 403:
			return { header: localized("unauthorized"), messageBody: localized("Api403Message") }
		default:
			return { header: message, messageBody: undefined }
	}
}

export const handleApiError = (error: any) => {
	if (error.status) {
		let apiException = showApiError(error);

		if (apiException.status === 401) {
			signOut();
		}
	} else {
		notificationService.showErrorMessageWithError(error.toString(), undefined, error);
	}
};

// If you are sending a non-arrow function to this function, then you need to wrap it in an arrow, since JS/TS cannot pass non-arrow functions as args
export const retryAsyncOperation = async <T>(
	operation: () => Promise<T>,
	delay: number,
	retries: number
): Promise<T> => {
	let lastError: any;

	for (let i = 0; i < retries; i++) {
		try {
			return await operation();
		} catch (error) {
			lastError = error;
			if (i < retries - 1) await wait(delay);
		}
	}
	throw lastError;
};

const wait = (ms: number) => new Promise(r => setTimeout(r, ms));
