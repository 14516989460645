import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { Routes } from 'state/ducks/routes';
import history from 'state/history';
import { AppState } from 'state/store';
import { PreAuthRedirectLocationKey } from 'utilities/constants';
import { authPermissionCheck } from './auth-helper';
import { AuthPageRequirement } from './auth-requirements';
import { FetchFailedCard } from './fetch-failed-card';
import { UnauthorizedCard } from './unauthorized-card';

interface PropsFromParent {}

const mapStateToProps = (state: AppState) => {
	return {
		isAuthenticated: state.authReducer.isAuthenticated,
		userGroupPermissions: state.userPermissionReducer.UserGroupPermissions,
		isNotInitializedOrLoading: !state.userPermissionReducer.isInitialized || state.userPermissionReducer.isLoading,
		fetchFailed: state.userPermissionReducer.fetchFailed,
	};
};

type Props = PropsFromParent &
	RouteProps &
	ReturnType<typeof mapStateToProps> & {
		authPageRequirement: AuthPageRequirement;
	};

class AuthRoute extends React.PureComponent<Props> {
	public render() {
		const { path, component, exact, authPageRequirement } = this.props;

		if (!this.props.isAuthenticated) {
			localStorage.setItem(PreAuthRedirectLocationKey, history.location.pathname);
			history.replace(Routes.SignInRedirect);
			return null;
		}
		if (this.props.isNotInitializedOrLoading) {
			return null;
		}
		if (authPermissionCheck(this.props.userGroupPermissions, authPageRequirement)) {
			return (
				<div>
					{exact ? (
						<Route exact={true} path={path} component={component} />
					) : (
						<Route path={path} component={component} />
					)}
				</div>
			);
		}
		if (this.props.fetchFailed) {
			return <FetchFailedCard />;
		} else {
			return <UnauthorizedCard />;
		}
	}
}
export default connect(mapStateToProps)(AuthRoute);
