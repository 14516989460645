import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { reactAI } from '../../../services/telemetry-service';
import * as actions from './actions';
import { UserPermissionState } from './types';

export const initialState: UserPermissionState = {
	UserGroupPermissions: [],
	isInitialized: false,
	isLoading: false,
	fetchFailed: false,
};

export function userPermissionReducer(state: typeof initialState = initialState, action: Action): typeof initialState {
	if (isType(action, actions.GetUserGroupPermissions.started)) {
		return {
			...state,
			isLoading: true,
			fetchFailed: false,
		};
	}

	if (isType(action, actions.GetUserGroupPermissions.done)) {
		return {
			...state,
			UserGroupPermissions: action.payload.result,
			isInitialized: true,
			isLoading: false,
			fetchFailed: false,
		};
	}

	if (isType(action, actions.GetUserGroupPermissions.failed)) {
		reactAI?.appInsights?.trackEvent({ name: 'Failed to fetch UserGroupPermissions' });
		return {
			...state,
			isInitialized: true,
			isLoading: false,
			fetchFailed: true,
		};
	}

	return state;
}
