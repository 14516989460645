import { IGroupsDto, IUserGroupPermissionsDto, RolesType } from 'api/api';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import {
	deleteUserGroupPermission,
	getUsersAdminGroupsChildHierarchy,
	resendConfirmationEmail,
} from 'state/ducks/groups/operations';
import { localized, localizedDynamic, localizedInterpolation } from 'state/i18n';
import { AppState } from 'state/store';
import { isAtLeastCountryAdminFromState, isAtLeastSuperAdminFromState } from 'utilities/roles-util';
import { getEmailFromToken } from 'utilities/token-util';
import { environmentPermissionCheck } from 'view/components/auth/auth-helper';
import { partnerAreaPageAuthRequirements } from 'view/components/auth/auth-requirements';
import BiTreeSelect from 'view/components/bi-tree-select/bi-tree-select';
import { TreeSelectItem } from 'view/components/bi-tree-select/tree-select-item';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';
import { isEditableField, UserExistInGroup } from './add-user-helpers';
import './user-field.scss';

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	deleteUser: async (userId: string, groupId: number, roleType: RolesType, previousGrpId?: number) =>
		(await deleteUserGroupPermission(userId, groupId, roleType, previousGrpId))(dispatch),
	resendConfirmationEmail: async (userId: string, groupId: number) =>
		(await resendConfirmationEmail(userId, groupId))(dispatch),
	getUsersAdminGroupsChildHierarchy: async () => (await getUsersAdminGroupsChildHierarchy())(dispatch),
});

const mapStateToProps = (state: AppState, props: ParentProps) => {
	const isAtLeastCountryAdminInUserGroup = isAtLeastCountryAdminFromState(state, props.user.groupId);
	const isSelfSuperAdmin = isAtLeastSuperAdminFromState(state);

	let userGroups: IGroupsDto[] | undefined;
	if (
		(props.user.roleType === RolesType.CountryAdmin && isAtLeastCountryAdminInUserGroup) ||
		(props.user.roleType === RolesType.SuperAdmin && isSelfSuperAdmin) ||
		props.user.roleType >= RolesType.Admin
	) {
		const userGroupDataTuples = state.groupsReducer.adminGroups.find(g => g.id === getEmailFromToken());
		if (userGroupDataTuples) {
			userGroups = userGroupDataTuples.data;
		}
	}

	const rowDisabled: boolean =
		(props.user.roleType === RolesType.CountryAdmin &&
			!isSelfSuperAdmin &&
			props.user.userId !== getEmailFromToken()) ||
		(props.user.roleType === RolesType.SuperAdmin && !isSelfSuperAdmin) ||
		(props.user.isServiceTechnician && !isAtLeastCountryAdminInUserGroup) ||
		(props.user.isServiceTool && !isAtLeastCountryAdminInUserGroup) ||
		(props.user.isServiceToolCode && !isAtLeastCountryAdminInUserGroup) ||
		(props.user.isPartnerArea && !isSelfSuperAdmin) ||
		(props.user.isAssemblyLine && !isSelfSuperAdmin);

	return {
		usersThatIOwn: state.groupsReducer.usersThatIOwn,
		userGroups: userGroups,
		isSelfSuperAdmin,
		isAtLeastCountryAdminInUserGroup,
		rowDisabled,
	};
};

interface ParentProps {
	user: IUserGroupPermissionsDto;
	groupHierarchy: TreeSelectItem[];
	isEditable?: boolean;
	upsertUser?: (userData: IUserGroupPermissionsDto, previousGroupId?: number) => Promise<boolean>;
	deleteUserData?: (user: IUserGroupPermissionsDto) => void;
	usersAdminGroupsData?: IGroupsDto[] | undefined;
}

interface State {
	previousGroup?: number;
	deleteDialogOpen: boolean;
	isSavingData: boolean;
}

type Props = ParentProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

class UserField extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		if (this.props.user) {
			this.state = {
				previousGroup: this.props.user.groupId,
				deleteDialogOpen: false,
				isSavingData: false,
			};
		}
	}

	public componentDidMount(): void {
		this.roleDropDown();
	}

	public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		this.roleDropDown();
	}

	public render() {
		return (
			<div id="user-manager-dropdowns">
				<BiTextDialog
					visible={this.state.deleteDialogOpen}
					onHide={this.closeDeleteDialog}
					title={localized('DeleteUser')}
					subtitle={localized('DeleteUserDialog')}
				>
					<div className="flex-end-row margin-top-10px">
						<BiButton
							colorTheme="org-primary-grey"
							containerTheme="slim-with-rounded-corners"
							containerClassName="margin-right-24px"
							onClick={this.closeDeleteDialog}
						>
							{localized('Cancel')}
						</BiButton>
						<BiButton
							colorTheme="org-red"
							containerTheme="slim-with-rounded-corners"
							onClick={this.deleteUser}
						>
							{localized('DeleteUser')}
						</BiButton>
					</div>
				</BiTextDialog>

				{this.getUserManagerDropdowns()}
			</div>
		);
	}

	private roleDropDown = () => {
		if (this.props.isSelfSuperAdmin) {
			const currentUserRoleType = Object.keys(RolesType)
				.filter(
					k =>
						typeof RolesType[k] === 'number' &&
						RolesType[k] <= RolesType.NoUnits &&
						RolesType[k] >= RolesType.SuperAdmin &&
						RolesType[k] !== RolesType.NonRegisteredAppUser
				)
				.map(label => ({ label: localizedDynamic(label), value: RolesType[label] }));
			return currentUserRoleType;
		} else {
			const currentUsersRoleInThisGroup = this.props.usersAdminGroupsData?.find(
				adminGroup => adminGroup.id === this.props.user.groupId
			);

			if (currentUsersRoleInThisGroup !== undefined) {
				const currentUserRoleType = Object.keys(RolesType)
					.filter(
						k =>
							typeof RolesType[k] === 'number' &&
							RolesType[k] < RolesType.NonRegisteredAppUser &&
							RolesType[k] >= currentUsersRoleInThisGroup?.roleType &&
							RolesType[k] !== RolesType.SuperAdmin &&
							(this.props.user.userId === getEmailFromToken() || RolesType[k] !== RolesType.CountryAdmin) // CountryAdmin role visible in disabled dropdown, but only selectable by superadmins
					)
					.map(label => ({ label: localizedDynamic(label), value: RolesType[label] }));
				return currentUserRoleType;
			}
		}
	};

	private getRoleDropDown = (): JSX.Element => {
		const roleTypes = this.roleDropDown();

		return (
			<Dropdown
				value={this.props.user.roleType}
				options={roleTypes}
				onChange={this.handleRoleChanged}
				placeholder={localized('selectARole')}
				disabled={this.props.rowDisabled}
			/>
		);
	};

	private closeDeleteDialog = () => {
		this.setState({ deleteDialogOpen: false });
	};

	private openDeleteDialog = () => {
		this.setState({ deleteDialogOpen: true });
	};

	private setIsServiceTechnician = async (e: { checked: boolean; value: any }) => {
		if (this.state.isSavingData || !this.props.upsertUser) return;

		this.setState({ isSavingData: true });

		const userGroupPermission: IUserGroupPermissionsDto = { ...this.props.user, isServiceTechnician: e.checked };
		notificationService.showInfoMessage(localizedInterpolation('UpdatingUser', { id: userGroupPermission.userId }));

		if (await this.props.upsertUser(userGroupPermission, userGroupPermission.groupId)) {
			notificationService.showSuccessMessage(
				localizedInterpolation(
					userGroupPermission.isServiceTechnician
						? 'AddedServiceTechnicianStatus'
						: 'RemovedServiceTechnicianStatus',
					{
						id: userGroupPermission.userId,
					}
				),
				undefined,
				8000
			);
		}

		this.setState({ isSavingData: false });
	};

	private getTechnicianCheckbox = (): JSX.Element => {
		return (
			<BiCheckbox
				checked={this.props.user.isServiceTechnician}
				disabled={this.props.rowDisabled || !this.props.isAtLeastCountryAdminInUserGroup}
				containerClassName="justify-self-center"
				onChange={this.setIsServiceTechnician}
			/>
		);
	};

	private setIsPartnerArea = async (e: { checked: boolean; value: any }) => {
		if (this.state.isSavingData || !this.props.upsertUser) return;

		this.setState({ isSavingData: true });

		const userGroupPermission: IUserGroupPermissionsDto = { ...this.props.user, isPartnerArea: e.checked };
		notificationService.showInfoMessage(localizedInterpolation('UpdatingUser', { id: userGroupPermission.userId }));

		if (await this.props.upsertUser(userGroupPermission, userGroupPermission.groupId)) {
			notificationService.showSuccessMessage(
				localizedInterpolation(
					userGroupPermission.isPartnerArea ? 'AddedPartnerAreaStatus' : 'RemovedPartnerAreaStatus',
					{
						id: userGroupPermission.userId,
					}
				),
				undefined,
				8000
			);
		}

		this.setState({ isSavingData: false });
	};

	private getPartnerAreaCheckbox = (): JSX.Element => {
		return (
			<BiCheckbox
				checked={this.props.user.isPartnerArea}
				disabled={!this.props.isSelfSuperAdmin}
				containerClassName="justify-self-center"
				onChange={this.setIsPartnerArea}
			/>
		);
	};

	private setIsServiceTool = async (e: { checked: boolean; value: any }) => {
		if (this.state.isSavingData || !this.props.upsertUser) return;

		this.setState({ isSavingData: true });

		const userGroupPermission: IUserGroupPermissionsDto = { ...this.props.user, isServiceTool: e.checked };
		notificationService.showInfoMessage(localizedInterpolation('UpdatingUser', { id: userGroupPermission.userId }));

		if (await this.props.upsertUser(userGroupPermission, userGroupPermission.groupId)) {
			notificationService.showSuccessMessage(
				localizedInterpolation(
					userGroupPermission.isServiceTool ? 'AddedServiceToolStatus' : 'RemovedServiceToolStatus',
					{
						id: userGroupPermission.userId,
					}
				),
				undefined,
				8000
			);
		}

		this.setState({ isSavingData: false });
	};

	private getServiceToolCheckbox = (): JSX.Element => {
		return (
			<BiCheckbox
				checked={this.props.user.isServiceTool}
				disabled={this.props.rowDisabled || !this.props.isAtLeastCountryAdminInUserGroup}
				containerClassName="justify-self-center"
				onChange={this.setIsServiceTool}
			/>
		);
	};

	private setIsServiceToolCode = async (e: { checked: boolean; value: any }) => {
		if (this.state.isSavingData || !this.props.upsertUser) return;

		this.setState({ isSavingData: true });

		const userGroupPermission: IUserGroupPermissionsDto = { ...this.props.user, isServiceToolCode: e.checked };
		notificationService.showInfoMessage(localizedInterpolation('UpdatingUser', { id: userGroupPermission.userId }));

		if (await this.props.upsertUser(userGroupPermission, userGroupPermission.groupId)) {
			notificationService.showSuccessMessage(
				localizedInterpolation(
					userGroupPermission.isServiceToolCode
						? 'AddedServiceToolCodeStatus'
						: 'RemovedServiceToolCodeStatus',
					{
						id: userGroupPermission.userId,
					}
				),
				undefined,
				8000
			);
		}

		this.setState({ isSavingData: false });
	};

	private getServiceToolCodeCheckbox = (): JSX.Element => {
		return (
			<BiCheckbox
				checked={this.props.user.isServiceToolCode}
				disabled={this.props.rowDisabled || !this.props.isAtLeastCountryAdminInUserGroup}
				containerClassName="justify-self-center"
				onChange={this.setIsServiceToolCode}
			/>
		);
	};

	private setAssemblyLine = async (e: { checked: boolean; value: any }) => {
		if (this.state.isSavingData || !this.props.upsertUser) return;

		this.setState({ isSavingData: true });

		const userGroupPermission: IUserGroupPermissionsDto = { ...this.props.user, isAssemblyLine: e.checked };
		notificationService.showInfoMessage(localizedInterpolation('UpdatingUser', { id: userGroupPermission.userId }));

		if (await this.props.upsertUser(userGroupPermission, userGroupPermission.groupId)) {
			notificationService.showSuccessMessage(
				localizedInterpolation(
					userGroupPermission.isAssemblyLine ? 'AddedAssemblyLineStatus' : 'RemovedAssemblyLineStatus',
					{
						id: userGroupPermission.userId,
					}
				),
				undefined,
				8000
			);
		}

		this.setState({ isSavingData: false });
	};

	private getAssemblyLineCheckBox = (): JSX.Element => {
		return (
			<BiCheckbox
				checked={this.props.user.isAssemblyLine}
				disabled={this.props.rowDisabled || !this.props.isSelfSuperAdmin}
				containerClassName="justify-self-center"
				onChange={this.setAssemblyLine}
			/>
		);
	};
	private getUserManagerDropdowns = () => {
		let mustHaveSelection: boolean = true;
		let deleteBtn: JSX.Element | null = null;
		let resendBtn: JSX.Element | null = null;
		let treeSelectors: JSX.Element;

		if (this.props.user.roleType === RolesType.SuperAdmin || this.props.rowDisabled) {
			const groupName = this.props.userGroups?.find(grp => grp.id === this.props.user.groupId)?.groupName ?? '';

			treeSelectors = (
				<>
					<p>{groupName}</p>
					{this.getRoleDropDown()}
					{this.getTechnicianCheckbox()}
					{this.getServiceToolCheckbox()}
					{this.getServiceToolCodeCheckbox()}
					{this.getAssemblyLineCheckBox()}
					{environmentPermissionCheck(partnerAreaPageAuthRequirements.environmemtSpecific) &&
						this.getPartnerAreaCheckbox()}
				</>
			);
		} else {
			if (!this.isFieldEditable()) {
				deleteBtn = (
					<BiTooltip overlay={localized('DeleteUser')}>
						<div className="flex-container">
							<i onClick={this.openDeleteDialog} className="pi pi-times-circle"></i>
						</div>
					</BiTooltip>
				);
			} else {
				mustHaveSelection = false;
			}
			resendBtn = this.getResendConfirmationBtn(this.props.user.emailConfirmed);
			treeSelectors = (
				<>
					<BiTreeSelect
						onCheckChanged={this.handleUserGroupChanged}
						nodes={this.props.groupHierarchy}
						selectedItems={this.props.user.groupId ? [this.props.user.groupId] : []}
						parentSelectedItems={true}
						userGroupPermissions={this.props.usersThatIOwn}
						userId={this.props.user.userId!}
						mustHaveSelection={mustHaveSelection}
					/>
					{this.getRoleDropDown()}
					{this.getTechnicianCheckbox()}
					{this.getServiceToolCheckbox()}
					{this.getServiceToolCodeCheckbox()}
					{this.getAssemblyLineCheckBox()}
					{environmentPermissionCheck(partnerAreaPageAuthRequirements.environmemtSpecific) &&
						this.getPartnerAreaCheckbox()}
					<div className="flex-end-row">
						{resendBtn}
						{deleteBtn}
					</div>
				</>
			);
		}
		const content = <div className="content-container-large">{treeSelectors}</div>;
		return content;
	};

	// The following will search all children of the provided parent for ["value"]s matching the provided ID and return the first node found.
	private findUserGroupNodeById = (id: number, parent: any): { label: string; value: string } => {
		const stack = [parent];
		while (stack.length) {
			const node: any = stack.pop();
			if (node.value === id) {
				return node;
			}
			if (node.children) stack.push(...node.children);
		}
		return stack.pop() || null;
	};

	private handleRoleChanged = async (e: { originalEvent: Event; value: any }) => {
		const group = this.findUserGroupNodeById(this.props.user.groupId, this.props.groupHierarchy[0]);
		let user = { ...this.props.user };
		user.roleType = e.value;
		if (this.props.upsertUser) {
			await this.props.upsertUser(user, this.state.previousGroup);

			const thisUserId = getEmailFromToken();
			if (this.props.user.userId === thisUserId) {
				this.props.getUsersAdminGroupsChildHierarchy();
			}

			if (!this.props.isEditable) {
				notificationService.showSuccessMessage(
					localizedInterpolation('changedRoleSuccess', {
						id: this.props.user.userId,
						role: RolesType[e.value],
						group: group.label,
					}),
					undefined,
					8000
				);
			}
		}
	};

	private handleUserGroupChanged = async (selectedGroups: number[], breadCrumb?: string) => {
		if (this.props.groupHierarchy.length && selectedGroups && selectedGroups.length) {
			const selectedGroup = selectedGroups[0];
			if (
				selectedGroup !== this.props.user.groupId &&
				!UserExistInGroup(this.props.user.userId!, selectedGroup, this.props.usersThatIOwn, false) &&
				this.props.upsertUser
			) {
				let user = { ...this.props.user };
				user.groupId = selectedGroup;
				this.props.upsertUser(user, this.state.previousGroup);
				if (!this.props.isEditable) {
					notificationService.showSuccessMessage(
						localizedInterpolation('changedGroupSuccess', {
							id: this.props.user.userId,
							group: breadCrumb,
						}),
						undefined,
						8000
					);
				}
			}
		}
	};

	private deleteUser = async () => {
		if (this.state.isSavingData) {
			return;
		}
		this.setState({ isSavingData: true });
		try {
			await this.props.deleteUser(
				this.props.user.userId!,
				this.props.user.groupId,
				this.props.user.roleType,
				this.state.previousGroup
			);
			this.setState({ deleteDialogOpen: false });
			if (this.props.deleteUserData) {
				this.props.deleteUserData(this.props.user);
			}
		} catch (e) {
			notificationService.showErrorMessage(localized('Error'));
		} finally {
			this.setState({ isSavingData: false });
		}
	};

	private isFieldEditable = (): boolean => {
		return this.props.isEditable !== false && isEditableField(this.props.user, this.props.usersThatIOwn);
	};

	private resendConfirmation = async () => {
		await this.props.resendConfirmationEmail(this.props.user.userId!, this.props.user.groupId);
		notificationService.showSuccessMessage(localized('ResendConfirmationSuccess'));
	};

	private getResendConfirmationBtn = (isConfirmed?: boolean): JSX.Element | null => {
		if (isConfirmed || this.isFieldEditable()) return null;

		return (
			<BiTooltip overlay={localized('ResendConfirmation')}>
				<div className="flex-container resend-btn">
					<i onClick={this.resendConfirmation} className="pi pi-replay cursor-pointer"></i>
				</div>
			</BiTooltip>
		);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserField);
