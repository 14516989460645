import { ContainerDetailDto, IContainerDetailDto, IUnitModelDto } from 'api/api';
import { Button } from 'primereact/button';
import { SelectItem } from 'primereact/components/selectitem/SelectItem';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useMemo, useState } from 'react';
import { notificationService } from 'services/notification-service';
import { createUnitModel, updateUnitModel } from 'state/ducks/unit-model/operations';
import { localized } from 'state/i18n';
import { LabelValuePair, toLabelValuePair } from 'types/label-value-pair';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { BiMultiselect, OnChangeEvent } from 'view/components/bi-multiselect/bi-multiselect';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import { isNullOrWhitespace } from 'view/shared/components/string-helpers';

interface Props {
	unitModel: IUnitModelDto;
	deleteUnitModel: (model: IUnitModelDto) => void;
	clearTempRow: () => void;
}

export const UnitModelField: React.FC<Props> = props => {
	const dispatch = useAppDispatch();

	const unitModels = useAppSelector(state => state.unitModelReducer.unitModels);

	const [tmpUnitModel, setTempUnitModel] = useState<IUnitModelDto>(props.unitModel);

	const filterOptions = useAppSelector(state => state.filterSearchReducer.filterOptions);
	const containerDetailsOptions: SelectItem[] = useMemo(() => filterOptions.containerSizeOptions, [
		filterOptions.containerSizeOptions,
	]);

	const selectedContainerDetails: number[] = useMemo(
		() => tmpUnitModel.containerDetailDtos?.map(val => val.containerSize) ?? [],
		[tmpUnitModel.containerDetailDtos]
	);

	useEffect(() => {
		setTempUnitModel({ ...props.unitModel });
	}, [props.unitModel]);

	const manufacturers = useAppSelector(state => state.unitManufacturerReducer.unitManufactures);
	const unitTypes = useAppSelector(state => state.unitTypeReducer.unitTypes);
	const unitModelCodes = useAppSelector(state => state.unitModelCodeReducer.unitModelCodes);

	const getUnitModelContent = () => {
		const unitModelCodeOptions: LabelValuePair<null | number>[] = [
			{ label: '-', value: null } as LabelValuePair<null | number>,
			...unitModelCodes.map(code => toLabelValuePair(code, 'name', 'id')),
		];

		const content = (
			<div className="content-container-unit-models">
				<InputText
					placeholder={localized('Name')}
					className="input-box-width-100-bramidan"
					onBlur={handleNameChanged}
					value={tmpUnitModel?.name}
					onChange={e => setTempUnitModel({ ...tmpUnitModel, name: e.currentTarget.value })}
				/>
				<Dropdown
					value={tmpUnitModel.unitTypeId}
					options={unitTypes.map(unitType => toLabelValuePair(unitType, 'nameTranslationKey', 'id', true))}
					onChange={handleUnitTypeChanged}
					placeholder={localized('Select')}
				/>
				<Dropdown
					value={tmpUnitModel.unitManufacturerId}
					options={manufacturers.map(manufacturer => toLabelValuePair(manufacturer, 'name', 'id'))}
					onChange={handleUnitManufacturerChanged}
					placeholder={localized('Select')}
				/>
				<Dropdown
					value={tmpUnitModel.unitModelCodeId}
					options={unitModelCodeOptions}
					onChange={handleUnitModelCodeChanged}
					placeholder={localized('Select')}
				/>
				<BiMultiselect
					placeholder={localized('Select')}
					options={containerDetailsOptions}
					onChange={handleContainerDetailChanged}
					selectedItems={selectedContainerDetails}
				/>
				<BiCheckbox
					checked={tmpUnitModel.isUserSelectable}
					containerClassName="justify-self-center"
					onChange={handleIsUserSelectableChanged}
				/>
				<span className="margin-left-auto">
					{tmpUnitModel.isDeletable && (
						<Button
							title={localized('Delete')}
							className={'p-button-custom bi-button-icon-only'}
							icon="pi pi-times-circle"
							onClick={() => props.deleteUnitModel(props.unitModel)}
						/>
					)}
					{tmpUnitModel.id === 0 && (
						<BiButton
							onClick={createModel}
							colorTheme={'org-primary'}
							containerTheme={'normal-button-corners'}
							disabled={
								isNullOrWhitespace(tmpUnitModel.name) ||
								!tmpUnitModel.unitTypeId ||
								!tmpUnitModel.unitManufacturerId
							}
						>
							{localized('Save')}
						</BiButton>
					)}
				</span>
			</div>
		);
		return content;
	};

	const handleNameChanged = async (e: React.FormEvent<HTMLInputElement>) => {
		var newModel: IUnitModelDto = { ...tmpUnitModel, name: e.currentTarget.value };
		await updateModel(newModel);
	};

	const handleUnitTypeChanged = async (e: { originalEvent: Event; value: number }) => {
		var newModel: IUnitModelDto = { ...tmpUnitModel, unitTypeId: e.value };
		await updateModel(newModel);
	};

	const handleUnitManufacturerChanged = async (e: { originalEvent: Event; value: number }) => {
		var newModel: IUnitModelDto = { ...tmpUnitModel, unitManufacturerId: e.value };
		await updateModel(newModel);
	};

	const handleUnitModelCodeChanged = async (e: { originalEvent: Event; value: number | null }) => {
		var newModel: IUnitModelDto = { ...tmpUnitModel, unitModelCodeId: e.value === null ? undefined : e.value };
		await updateModel(newModel);
	};

	const handleContainerDetailChanged = async (e: OnChangeEvent) => {
		var newModel: IUnitModelDto = {
			...tmpUnitModel,
			containerDetailDtos: (e.value as number[]).map(val => {
				return ContainerDetailDto.fromJS({ containerSize: val } as IContainerDetailDto);
			}),
		};
		await updateModel(newModel);
	};

	const handleIsUserSelectableChanged = async (e: { checked: boolean; value: any }) => {
		var newModel: IUnitModelDto = { ...tmpUnitModel, isUserSelectable: e.checked };
		await updateModel(newModel);
	};

	const isNameValid = (model: IUnitModelDto): boolean => {
		if (unitModels.map(um => um.name).includes(model.name)) return false;
		return true;
	};

	const updateModel = async (unitModel: IUnitModelDto) => {
		setTempUnitModel(unitModel);
		if (unitModel.id === 0) return;
		await updateUnitModel(unitModel)(dispatch);
		notificationService.showSuccessMessage(localized('Saved'), undefined);
	};

	const createModel = async () => {
		if (!isNullOrWhitespace(tmpUnitModel.name) && isNameValid(tmpUnitModel)) {
			await createUnitModel(tmpUnitModel)(dispatch);
			notificationService.showSuccessMessage(localized('Saved'), undefined);
			props.clearTempRow();
		}
	};

	return <div className="unit-model-field">{getUnitModelContent()}</div>;
};
