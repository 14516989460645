import React from 'react';
import { localized } from 'state/i18n';
import BiTitle from 'view/shared/components/bi-title/bi-title';
import WhiteCard from 'view/shared/components/cards/white-card';
import './failed-auth-card.scss';

export const FetchFailedCard: React.FC<{}> = props => {
	return (
		<BiTitle title={localized('unauthorized')}>
			<div className="failed-auth-card-container">
				<WhiteCard>
					<div className="flex-space-between-row flex-wrap">
						<div className="margin-bottom-10px">
							<h3 className="text-bold margin-bottom-0">{localized('sorry')}</h3>
						</div>
					</div>
					<div className="margin-bottom-10px">
						<p>{localized('permissionFetchFailedMessage')}</p>
					</div>
				</WhiteCard>
			</div>
		</BiTitle>
	);
};
