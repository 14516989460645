import { UserSettingsState } from './types';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import * as actions from './actions';
import { SelectedLanguage, ProfileUnit, UserSettings } from 'api/api';
import { changeLanguage } from 'state/i18n';
import isEqual from 'react-fast-compare';
import { getSelectedLanguage } from 'utilities/language-util';

export const initialState: UserSettingsState = {
	isLoadingUserSettings: false,
	userSettings: UserSettings.fromJS({
		profileUnits: ProfileUnit.Metric,
		selectedLanguage: SelectedLanguage.En,
	}),
};

export function userSettingReducer(state: UserSettingsState = initialState, action: Action): UserSettingsState {
	if (isType(action, actions.getUserSettingsAction.started)) {
		return { ...state, isLoadingUserSettings: true };
	} else if (isType(action, actions.getUserSettingsAction.done)) {
		if (
			state.userSettings &&
			action.payload.result &&
			!isEqual(state.userSettings.selectedLanguage, action.payload.result.selectedLanguage)
		) {
			changeLanguage(getSelectedLanguage(action.payload.result.selectedLanguage));
		}

		return !!action.payload.result
			? {
					...state,
					userSettings: action.payload.result,
					isLoadingUserSettings: false,
			  }
			: {
					...state,
					isLoadingUserSettings: false,
			  };
	} else if (isType(action, actions.upsertUserSettingsAction.done)) {
		return {
			...state,
			userSettings: action.payload.result,
			isLoadingUserSettings: false,
		};
	}

	return state;
}
