import { SelectItem } from 'primereact/components/selectitem/SelectItem';
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect';
import React, { useState } from 'react';
import './bi-multiselect.scss';

interface Props extends MultiSelectProps {
	options?: SelectItem[];
	onChange?: (e: OnChangeEvent) => void;
	onEnterPressed?: (e: KeyboardEvent) => void;
	filter?: boolean;
	placeholder?: string;
	selectedItems: any[];
}

export interface OnChangeEvent {
	originalEvent: Event;
	value: any;
}

export const BiMultiselect: React.FC<Props> = props => {
	const [isOpen, setIsOpen] = useState(false);

	const onChangeEvent = (e: OnChangeEvent) => {
		if ((e.originalEvent as any).key === 'Enter' && props.onEnterPressed) {
			setIsOpen(false);
			props.onEnterPressed(e.originalEvent as KeyboardEvent);
		} else if (props.onChange) {
			props.onChange(e);
		}
	};
	const onFocus = () => {
		document.addEventListener('keypress', enterKeyPressed);
	};

	const enterKeyPressed = (e: KeyboardEvent) => {
		if (props.onEnterPressed) {
			props.onEnterPressed(e);
		}
		document.removeEventListener('keypress', enterKeyPressed);
	};

	const multiSelectClassName = 'p-multiselect';

	return (
		<MultiSelect
			placeholder={props.placeholder}
			className={
				multiSelectClassName +
				(isOpen ? ' ' : ' hide') +
				(props.selectedItems && props.selectedItems.length > 0 ? ' not-empty no-select' : ' no-select')
			}
			onFocus={onFocus}
			value={props.selectedItems}
			options={props.options?.sort((a, b) => ((a.label ?? '') > (b.label ?? '') ? 1 : -1))}
			onChange={onChangeEvent}
			filter={props.filter}
			{...props}
		/>
	);
};
