import { IUserGroupPermissionsDto } from 'api/api';
import { InputText } from 'primereact/inputtext';
import { PageState, Paginator } from 'primereact/paginator';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	createUserGroupPermission,
	getUserGroupsChildHierarchy,
	getUsersAdminGroupsChildHierarchy,
	getUsersCountryAdminGroupsChildHierarchy,
	getUsersThatIOwn,
	upsertUserGroupPermission,
} from 'state/ducks/groups/operations';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { mapTreeItemsToTreeSelectItems } from 'utilities/array-to-tree';
import { remove, upsert } from 'utilities/reducer-helpers';
import { getEmailFromToken } from 'utilities/token-util';
import { ValidateUserEmail } from 'utilities/user-input-validation-util';
import { environmentPermissionCheck } from 'view/components/auth/auth-helper';
import { partnerAreaPageAuthRequirements } from 'view/components/auth/auth-requirements';
import { TreeSelectItem } from 'view/components/bi-tree-select/tree-select-item';
import { BiLabelContent } from 'view/shared/components/bi-label-content/bi-label-content';
import BiLabelContentItem from 'view/shared/components/bi-label-content/bi-label-content-item';
import { UserExistInGroup, UserInputIsValid } from './add-user-helpers';
import UserField from './user-field';
import './user-manager.scss';

const mapStateToProps = (state: AppState) => {
	const usersAdminGroupsTuple = state.groupsReducer.adminGroups.find(g => g.id === getEmailFromToken());
	const usersAdminGroupData = usersAdminGroupsTuple ? usersAdminGroupsTuple.data : undefined;
	return {
		userGroups: state.groupsReducer.groups.find(g => g.id === getEmailFromToken()),
		usersAdminGroups: usersAdminGroupsTuple,
		usersAdminGroupData: usersAdminGroupData,
		usersThatIOwn: state.groupsReducer.usersThatIOwn,
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getUserGroupsChildHierarchy: async () => (await getUserGroupsChildHierarchy())(dispatch),
	getUsersAdminGroupsChildHierarchy: async () => (await getUsersAdminGroupsChildHierarchy())(dispatch),
	getUsersCountryAdminGroupsChildHierarchy: async () => (await getUsersCountryAdminGroupsChildHierarchy())(dispatch),
	getUsersThatIOwn: async (groupIds: number[]) => (await getUsersThatIOwn(groupIds))(dispatch),
	upsertUserGroupPermission: async (userGroupPermission: IUserGroupPermissionsDto, previousGroupId?: number) =>
		(await upsertUserGroupPermission(userGroupPermission, previousGroupId))(dispatch),
	createUserGroupPermission: async (userGroupPermission: IUserGroupPermissionsDto) =>
		(await createUserGroupPermission(userGroupPermission))(dispatch),
});

interface AppProps {
	isAdmin?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

interface State {
	newUserEmail: string;
	editableUser: IUserGroupPermissionsDto;
	paginatorFirst: number;
	paginatorPageCount?: number;
	paginatorCurrentPage: number;
	paginatorRows: number;
	newlyCreatedFields: IUserGroupPermissionsDto[];
	searchBarText: string;
	paginationRecordLength: number;
}

// Only return the distinct enum values. Object.keys(...) without filter will produce two keys for each numeric value
// Related: https://stackoverflow.com/a/54341904
// export let notificationServiceList = Object.keys(NotificationType).filter(e => !isNaN(Number(e)));

class UserManager extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			newlyCreatedFields: [],
			searchBarText: '',
			newUserEmail: '',
			paginatorFirst: 0,
			paginatorRows: 10,
			paginatorCurrentPage: 0,
			paginationRecordLength: 0,
			editableUser: {
				groupId: 0,
				roleType: undefined!,
				userId: '',
				isServiceTechnician: false,
				isServiceTool: false,
				isServiceToolCode: false,
				isPartnerArea: false,
				isAssemblyLine: false,
			},
		};
		this.props.getUsersCountryAdminGroupsChildHierarchy();
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		if (this.state.paginatorPageCount) {
			// If paginator is on the last page, and user deletes the last row in pagination
			if (
				this.state.paginatorCurrentPage + 1 === this.state.paginatorPageCount &&
				this.state.paginationRecordLength === this.state.paginatorFirst &&
				prevState.paginationRecordLength !== this.state.paginationRecordLength
			) {
				const setSecondLastPage = (this.state.paginatorCurrentPage - 1) * this.state.paginatorRows;
				this.setState({
					paginatorFirst: setSecondLastPage,
					paginatorCurrentPage: this.state.paginatorCurrentPage - 1,
				});
			}
		}
	}

	public async componentDidMount() {
		if (!this.props.usersAdminGroups) {
			await this.props.getUsersAdminGroupsChildHierarchy();
		}
		const allGroups = this.props.usersAdminGroups && this.props.usersAdminGroups.data.map(l => l.id);
		const uniqueItems = Array.from(new Set(allGroups));
		await this.props.getUsersThatIOwn(uniqueItems);
	}

	public render() {
		let usersThatIOwn: JSX.Element[] | null = null;
		let paginationRecordLength: number;
		let usersToDisplay: IUserGroupPermissionsDto[];
		if (this.state.searchBarText.length) {
			const lowercasedFilter = this.state.searchBarText.toLowerCase();
			usersToDisplay = this.props.usersThatIOwn.filter(usr => {
				let groupName: string = '';
				if (this.props.usersAdminGroupData) {
					const group = this.props.usersAdminGroupData.find(grp => grp.id === usr.groupId);
					groupName = group && group.groupName ? group.groupName : '';
				}
				return (
					usr.userId!.toLowerCase().includes(lowercasedFilter) ||
					groupName.toLowerCase().includes(lowercasedFilter)
				);
			});
			paginationRecordLength = usersToDisplay.length;
		} else {
			usersToDisplay = this.props.usersThatIOwn;
			paginationRecordLength = this.props.usersThatIOwn.length;
		}

		this.setState({ paginationRecordLength: paginationRecordLength });
		usersThatIOwn = this.getPaginationUserDataRows(usersToDisplay);
		const editableField = this.props.isAdmin ? this.getEditableField() : null;
		const newlyCreatedFields = this.getNewlyCreatedField();
		return (
			<div>
				<h6 className="text-bold">{localized('FilterUsersByEmailOrGroup')}</h6>
				<div className="p-inputgroup margin-bottom-20px">
					<div className="bi-container flex-direction-column flex-fill-width">
						<div className="flex-start-row search-bar margin-bottom-10pxs">
							<InputText
								placeholder={localized('Search')}
								value={this.state.searchBarText}
								onChange={this.onSearchBarTextChanged}
							/>
						</div>
						{usersThatIOwn && usersThatIOwn.length ? (
							<div className="flex-fill-width">
								<BiLabelContent containerClassName="flex-fill-width">
									<BiLabelContentItem
										defaultClassNames="bi-label-content-item-lg-3"
										content={this.UserManagementLabels()}
										showBoxShadow={false}
										label={''}
									/>
									{usersThatIOwn}
									{newlyCreatedFields}
									{editableField}
									<div className="flex-end-row">
										<Paginator
											first={this.state.paginatorFirst}
											rows={this.state.paginatorRows}
											totalRecords={paginationRecordLength}
											onPageChange={this.onPageChangeHandler}
											template="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
										></Paginator>
									</div>
								</BiLabelContent>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}

	private onSearchBarTextChanged = (e: React.FormEvent<HTMLInputElement>) => {
		this.setState({
			searchBarText: e.currentTarget.value,
			newlyCreatedFields: [],
			paginatorFirst: 0,
			paginatorCurrentPage: 0,
		});
	};

	private onPageChangeHandler = (e: PageState) => {
		this.setState({
			paginatorFirst: e.first,
			paginatorRows: e.rows,
			paginatorCurrentPage: e.page,
			paginatorPageCount: e.pageCount,
			newlyCreatedFields: [],
		});
	};

	private getPaginationUserDataRows = (
		usersThatIOwn: IUserGroupPermissionsDto[],
		paginatorFirst?: number
	): JSX.Element[] => {
		const users: IUserGroupPermissionsDto[] = usersThatIOwn.slice(
			paginatorFirst ? paginatorFirst : this.state.paginatorFirst,
			this.state.paginatorFirst + this.state.paginatorRows
		);
		return this.getUserDataRows(users);
	};

	private getUserDataRows = (users: IUserGroupPermissionsDto[]): JSX.Element[] => {
		const groupHierarchy: TreeSelectItem[] = this.getGroupHierachyBasedOnAdmin();
		const usersToDisplay = users.map(user => {
			return (
				<BiLabelContentItem
					containerClassName="margin-bottom-10px"
					defaultClassNames="bi-label-content-item-lg-3"
					label={user.userId}
					key={`${user.groupId}${user.userId}`}
					content={
						<UserField
							isEditable={false}
							user={user}
							groupHierarchy={groupHierarchy}
							upsertUser={this.updateUserData}
							usersAdminGroupsData={this.props.usersAdminGroupData}
						/>
					}
				/>
			);
		});
		return usersToDisplay;
	};

	private getNewlyCreatedField = (): JSX.Element[] => {
		let groupHierarchy: TreeSelectItem[] = this.getGroupHierachyBasedOnAdmin();
		const usersToDisplay = this.state.newlyCreatedFields.map(user => {
			return (
				<BiLabelContentItem
					containerClassName="margin-bottom-10px"
					defaultClassNames="bi-label-content-item-lg-3"
					label={user.userId}
					key={`${user.groupId}${user.userId}`}
					content={
						<UserField
							isEditable={false}
							deleteUserData={this.deleteUserDataInNewlyCreatedField}
							upsertUser={this.getUserDataFromNewlyCreatedField}
							user={user}
							groupHierarchy={groupHierarchy}
							usersAdminGroupsData={this.props.usersAdminGroupData}
						/>
					}
				/>
			);
		});
		return usersToDisplay;
	};

	private getEditableField = () => {
		let groupHierarchy: TreeSelectItem[] = this.getAdminGroupHierarchy();
		return (
			<BiLabelContentItem
				containerClassName="margin-bottom-10px"
				defaultClassNames="bi-label-content-item-lg-3"
				label={this.EmailFieldLabel()}
				content={
					<UserField
						user={this.state.editableUser}
						upsertUser={this.getUserDataFromEditableField}
						groupHierarchy={groupHierarchy}
						isEditable={true}
						usersAdminGroupsData={this.props.usersAdminGroupData}
					/>
				}
			/>
		);
	};

	private getUserDataFromEditableField = async (userData: IUserGroupPermissionsDto, previousGroupId?: number) => {
		let hasUpserted: boolean = false;
		if (UserInputIsValid(userData)) {
			if (!UserExistInGroup(userData.userId!, userData.groupId, this.props.usersThatIOwn)) {
				hasUpserted = await this.insertUserData(userData);
				const pagesInPagination: number = this.getPagesInPagination();

				this.setState({ paginatorPageCount: pagesInPagination });

				let isPaginationOnLastPage: boolean = false;
				if (pagesInPagination === this.state.paginatorCurrentPage + 1) {
					isPaginationOnLastPage = true;
				}

				// Reset data in editable field
				this.setState(prevState => ({
					editableUser: {
						groupId: 0,
						roleType: undefined!,
						userId: '',
						isServiceTechnician: false,
						isServiceTool: false,
						isServiceToolCode: false,
						isPartnerArea: false,
						isAssemblyLine: false,
					},
					newUserEmail: '',
					newlyCreatedFields: isPaginationOnLastPage ? [] : [...prevState.newlyCreatedFields, userData],
				}));
			} else {
				let userDataCopy = { ...userData };
				userDataCopy.groupId = 0;
				this.setState({ editableUser: userDataCopy });
			}
		} else {
			this.setState({ editableUser: userData });
		}
		return hasUpserted;
	};
	private getUserDataFromNewlyCreatedField = async (userData: IUserGroupPermissionsDto, previousGroupId?: number) => {
		const hasUpserted = await this.updateUserData(userData, 0);

		if (hasUpserted) {
			const index = this.state.newlyCreatedFields.findIndex(
				usr => usr.userId === userData.userId && usr.groupId === previousGroupId
			);
			this.setState({
				newlyCreatedFields: upsert(this.state.newlyCreatedFields, userData, index),
			});
		}
		return hasUpserted;
	};

	private deleteUserDataInNewlyCreatedField = (userData: IUserGroupPermissionsDto) => {
		const index = this.state.newlyCreatedFields.indexOf(userData);
		this.setState({
			newlyCreatedFields: remove(this.state.newlyCreatedFields, index),
		});
	};

	private updateUserData = async (userData: IUserGroupPermissionsDto, previousGroupId?: number): Promise<boolean> => {
		let hasUpserted: boolean = false;

		await this.props.upsertUserGroupPermission(userData, previousGroupId);
		hasUpserted = true;

		return hasUpserted;
	};

	private insertUserData = async (userData: IUserGroupPermissionsDto): Promise<boolean> => {
		let hasUpserted: boolean = false;

		await this.props.createUserGroupPermission(userData);
		hasUpserted = true;

		return hasUpserted;
	};

	private EmailFieldLabel = () => {
		return (
			<span>
				<InputText
					placeholder="example@mail.com"
					className="input-box-bramidan"
					onBlur={this.EmailFieldOnBlur}
					value={this.state.newUserEmail}
					onChange={this.EmailFieldOnChange}
					autoComplete="off"
				/>
			</span>
		);
	};

	private EmailFieldOnChange = (event: React.FormEvent<HTMLInputElement>) => {
		const val = event.currentTarget.value;
		this.setState({ newUserEmail: val });
	};

	private EmailFieldOnBlur = async () => {
		const userId = this.state.newUserEmail;
		let editableUserData = { ...this.state.editableUser };
		editableUserData.userId = userId;
		if (ValidateUserEmail(editableUserData.userId)) {
			this.getUserDataFromEditableField(editableUserData);
		}
	};

	private UserManagementLabels = () => (
		<div className="content-container-large">
			<label>{localized('Group')}</label>
			<label>{localized('Role')}</label>
			<label className="justify-self-center">{localized('Technician')}</label>
			<label className="justify-self-center">{localized('ServiceTool')}</label>
			<label className="justify-self-center">{localized('ServiceToolCode')}</label>
			<label className="justify-self-center">{localized('AssemblyLine')}</label>
			{environmentPermissionCheck(partnerAreaPageAuthRequirements.environmemtSpecific) && (
				<label className="justify-self-center">{localized('PartnerArea')}</label>
			)}
			<span />
		</div>
	);

	private getGroupHierachyBasedOnAdmin = (): TreeSelectItem[] => {
		let groupHierarchy: TreeSelectItem[];
		if (this.props.isAdmin) {
			groupHierarchy = this.getAdminGroupHierarchy();
		} else {
			groupHierarchy = this.getGroupHierarchy();
		}
		return groupHierarchy;
	};
	private getAdminGroupHierarchy = (): TreeSelectItem[] => {
		let res = this.props.usersAdminGroups ? mapTreeItemsToTreeSelectItems(this.props.usersAdminGroups.data) : [];
		return res;
	};

	private getGroupHierarchy = (): TreeSelectItem[] => {
		return this.props.userGroups ? mapTreeItemsToTreeSelectItems(this.props.userGroups.data) : [];
	};

	private getPagesInPagination = (): number => {
		return Math.ceil(this.state.paginationRecordLength / this.state.paginatorRows);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManager);
