import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { localized, localizedInterpolation } from 'state/i18n';
import { AppStoreUrl, GooglePlayUrl } from 'utilities/constants';
import SetUserLanguagePicker from 'view/shared/components/bi-language-picker/set-user-language-picker';
import BiTitle from 'view/shared/components/bi-title/bi-title';
import WhiteCard from 'view/shared/components/cards/white-card';
import { reactAI } from '../../../services/telemetry-service';
import { useAppSelector } from '../../../utilities/hooks';
import './failed-auth-card.scss';

export const UnauthorizedCard: React.FC<{}> = props => {
	const location = useLocation();
	const permissions = useAppSelector(state => state.userPermissionReducer.UserGroupPermissions);

	useEffect(() => {
		reactAI?.appInsights?.trackEvent({
			name: 'UnauthorizedCard, appeared to user',
			properties: {
				route: location.pathname,
				groupRoles: permissions.map(permission => `${permission.groupId}:${permission.roleType}`).join(', '),
			},
		});
	}, []);

	return (
		<BiTitle title={localized('unauthorized')}>
			<div className="failed-auth-card-container">
				<WhiteCard>
					<div className="flex-space-between-row flex-wrap">
						<div className="margin-bottom-10px">
							<h3 className="text-bold margin-bottom-0">{localized('sorry')}</h3>
							<span className="text-bold">{localized('noToWebsiteAccess')}</span>
						</div>
						<div className="margin-bottom-10px">
							<SetUserLanguagePicker />
						</div>
					</div>
					<div className="margin-bottom-10px">
						<p>
							{localizedInterpolation('unauthorizedMessage', {
								basicUser: localized('BasicUser'),
							})}
						</p>
						<p>{localized('downloadApp')}</p>
					</div>
					<div className="text-bold flex-wrap">
						<a href={AppStoreUrl || '#'} className="margin-right-14px">
							{localized('downloadOnAppStore')}
						</a>
						<a href={GooglePlayUrl || '#'}>{localized('downloadOnGooglePlay')}</a>
					</div>
				</WhiteCard>
			</div>
		</BiTitle>
	);
};
